<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-text
              :editable="editable"
              :disabled="true"
              :afterIcon="[
                { name: 'search', click: true, callbackName: 'searchHazard', color: 'teal' },
                { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' }
              ]"
              label="유해인자"
              name="hazardNames"
              v-model="searchParam.hazardNames"
              @searchHazard="openHazard"
              @removeHazard="removeHazard">
            </c-text>
          </div>
        </template>
      </c-search-box>
      <div class="col-12">
        <apexchart 
          ref="chart" 
          height="300" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
      <div class="col-12">
        <c-table
          ref="table"
          title="통계조사표"
          tableId="table"
          :merge="grid.merge"
          gridHeight="360px"
          :columns="grid.columns"
          :data="grid.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'testimonial-person-graph3',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        hazardNames: '',
        hazardList: '',
        startYmd: '',
        endYmd: '',
      },
      year: ['', ''],
      chart: {
        chartOptions: {
         // 그래프 설정
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: true
            },
          },
          title: {
            text: '유해인자별 유소견자 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '명'
            }
          },
          fill: {
            opacity: 0.9
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + "명"
              }
            }
          },
        },
        series: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'hazardName' },
        ],
        columns: [
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'diseaseName',
            field: 'diseaseName',
            label: '질환',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'judgmentName',
            field: 'judgmentName',
            label: '판정',
            align: 'center',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '유소견자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'appointDate',
            field: 'appointDate',
            label: '지정일',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.hea.checkup.suspect.graph.hazard.url;
      // 년도 설정
      let years = [];
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.chart.chartOptions.xaxis.categories = years;

      this.searchParam.startYmd = years[0];
      this.searchParam.endYmd = years[4];

      this.getList();
      this.setChart();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.setGraph();
      },);
    },
    setGraph() {
      let graphDatas = this.$_.clone(this.grid.data);
      this.chart.series = [];
      this.$_.forEach(graphDatas, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
        let idx = this.chart.chartOptions.xaxis.categories.indexOf(_item.appointYear)

        // 부서 중복 제거
        if (this.$_.findIndex(this.chart.series, { hazardCd: _item.hazardCd }) === -1) {
          this.chart.series.push({
            hazardCd: _item.hazardCd,
            name: _item.hazardName,
            data: [0, 0, 0, 0, 0]
          })
        }
        this.chart.series[this.$_.findIndex(this.chart.series, { hazardCd: _item.hazardCd })].data[idx] ++;
      })
      this.setChart();
    },
    setChart() {
      setTimeout(() => {
        this.chart.chartWidth = '90%';
      }, 200);
      this.$refs['chart'].refresh();
    },
    openHazard() {
      this.popupOptions.title = '유해인자 검색'; // 유해인자 검색
      this.popupOptions.param = {
        type: 'multiple',
        speHealthFlag: 'Y',
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/harmfulFactorsPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.searchParam.hazardList = [];
      this.searchParam.hazardNames = '';
      let count = 0;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          count++;
          this.searchParam.hazardList.push(_item.hazardCd);

          if (count === data.length) {
            this.searchParam.hazardNames += _item.hazardName;
          } else {
            this.searchParam.hazardNames += _item.hazardName + ', ';
          }
        })
      }
    },
    removeHazard() {
      this.searchParam.hazardList = [];
      this.searchParam.hazardNames = '';
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>